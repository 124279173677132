import React, { useState, useEffect } from "react"
import { Table, Tooltip, Tag, Button, Row, Col, Popconfirm, Form, Input } from "antd"
import apiManager from "../../api/apiManager"
import Loader from "../Loader/Loader"
import { SyncProblem, ContactEmergency, Replay, Person, Download, Receipt } from "@mui/icons-material"
import { SearchOutlined } from "@ant-design/icons"
import DeliveryModal from "../Modals/DeliveryModal"
import GenerateDelivery from "../Modals/GenerateDelivery"
//import "./tabledelivery.css"

export default function TableDelivery() {

  useEffect(()=> {
    loadTableData()
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [dataTableBase, setDataTableBase] = useState([])
  const [periodDelivery, setPeriodDelivery] = useState([])
  const [channelsDelivery, setChannelsDelivery] = useState([])
  const [socialDelivery, setSocialDelivery] = useState([])
  const [socialFactDelivery, setSocialFactDelivery] = useState([])
  const [bookingsDelivery, setBookingsDelivery] = useState([])
  const [isModalDelivery, setIsModalDelivery] = useState(false)
  const [isModalGenerateDelivery, setIsModalGenerateDelivery] = useState(false)
  const [deliveryData, setDeliveryData] = useState([])
  const [formSearch] = Form.useForm()

  const loadTableData = () => {
    setIsLoading(true)
    apiManager.listDelivery(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      filterSocialDelivery(result)
      filterSocialFactDelivery(result)
      filterChannelsDelivery(result)
      filterPeriodsDelivery(result)
      filterBookingsDelivery(result)
      setDataTable(result)
      setDataTableBase(result)
      setIsLoading(false)
    })
  }

  const filterPeriodsDelivery = (elements) => {
    setPeriodDelivery([])
    let list = []
    elements.map(delivery => {
      if (list.some((e) => e.value === delivery.delivery_period)) {
        
      } else {
        list.push({value: delivery.delivery_period})
        setPeriodDelivery(current => [...current, {
          'text': delivery.delivery_period,
          'value': delivery.delivery_period
        }])
      }
    })
  }

  const filterChannelsDelivery = (elements) => {
    setChannelsDelivery([])
    let list = []
    elements.map(delivery => {
      if (list.some((e) => e.value === delivery.sales_channel)) {
        
      } else {
        list.push({value: delivery.sales_channel})
        setChannelsDelivery(current => [...current, {
          'text': delivery.sales_channel,
          'value': delivery.sales_channel
        }])
      }
    })
  }

  const filterSocialDelivery = (elements) => {
    setSocialDelivery([])
    let list = []
    elements.map(delivery => {
      if (
        list.some((e) => e.value === delivery.social_fact) ||
        delivery.social_fact === "" ||
        delivery.social_fact === undefined
      ) {} else {
        list.push({value: delivery.social_fact})
        setSocialDelivery(current => [...current, {
          'text': delivery.social_fact,
          'value': delivery.social_fact
        }])
      }
    })
  }

  const filterSocialFactDelivery = (elements) => {
    setSocialFactDelivery([])
    let list = []
    elements.map(delivery => {
      if (
        list.some((e) => e.value === delivery.social_reason_fact) ||
        delivery.social_reason_fact === "" ||
        delivery.social_reason_fact === undefined
      ) {} else {
        list.push({value: delivery.social_reason_fact})
        setSocialFactDelivery(current => [...current, {
          'text': delivery.social_reason_fact,
          'value': delivery.social_reason_fact
        }])
      }
    })
  }

  const filterBookingsDelivery = (elements) => {
    setBookingsDelivery([])
    let list = []
    elements.map(delivery => {
      delivery.bookings.map(booking => {
        if (list.some((e) => e.value === booking)) {
        
        } else {
          list.push({value: booking})
          setBookingsDelivery(current => [...current, {
            'text': booking,
            'value': booking
          }])
        }
      })
    })
  }

  const recreateDelivery = (value) => {
    setIsLoading(true)
    let data = {delivery_id: value}
    apiManager.recreateDelivery(data)
    .then((res) => {
      setIsLoading(false)
      loadTableData()
    })
  }

  const openInfoDelivery = (value) => {
    apiManager.getDelivery(value)
    .then((res) => {
      let result = res.data.data[0]
      setDeliveryData(result)
      setIsModalDelivery(true)
      //console.log(result)
    })
  }

  const closeInfoDelivery = () => {
    setIsModalDelivery(false)
  }

  const closeGenerateDelivery = () => {
    setIsModalGenerateDelivery(false)
  }

  const generateDelivery = (bookingId) => {
    setIsLoading(true)
    let data = {
      booking_id: bookingId
    }
    apiManager.deliveryCreate(data)
    .then((res) => {
      //console.log(res.data.data)
      closeGenerateDelivery(false)
      loadTableData()
      setIsLoading(false)
    })
  }

  const generateFact = (value) => {
    setIsLoading(true)
    let data = {
      delivery_id: value
    }
    apiManager.generateFact(data)
    .then((res) => {
      console.log(res.data.data)
      loadTableData()
      setIsLoading(false)
    })
  }

  const searchOnTable = (text) => {
    if (text.text !== undefined && text.text !== "") {
      let tableData = dataTableBase
      console.log(tableData)
      const filterResult = tableData.filter(
        (item) =>
          item.client
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.delivery_id
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.social_fact.toLowerCase().includes(text.text.toLowerCase()) ||
          item.sales_channel.toLowerCase().includes(text.text.toLowerCase()) ||
          item.delivery_date.toLowerCase().includes(text.text.toLowerCase())
      )
      setDataTable(filterResult)
    } else {
      setDataTable(dataTableBase)
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'delivery_id',
      key: 'status_delivery',
      align: 'center',
      render: (item, record) =>
        <div style={{display: 'flex', gap: 5, justifyContent: 'center'}}>
          {
            record.need_recreate ?
              <Tooltip title="Necesita realbaraneado"><Replay style={{fill: '#fff', backgroundColor: 'orange', borderRadius: '50%', padding: 2, fontSize: 20}} /></Tooltip>
            : <></>
          }
          {
            record.need_info_client ?
            <Tooltip title="Requiere información del cliente"><Person style={{fill: '#fff', backgroundColor: 'red', borderRadius: '50%', padding: 2, fontSize: 20}} /></Tooltip>
            : <></>
          }
        </div>
    },
    {
      title: 'Delivery note date',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      sorter: (a, b) => a.delivery_date_ts - b.delivery_date_ts,
    },
    {
      title: 'ID Albarán',
      dataIndex: 'delivery_id',
      key: 'delivery_id',
      sorter: (a, b) => a.delivery_id.localeCompare(b.delivery_id),
    },
    {
      title: 'Periodo albarán',
      dataIndex: 'delivery_period',
      key: 'delivery_period',
      filters: periodDelivery,
      onFilter: (value, record) => record.delivery_period.includes(value) === true,
    },
    {
      title: 'Partners',
      dataIndex: 'sales_channel',
      key: 'sales_channel',
      filters: channelsDelivery,
      onFilter: (value, record) => record.sales_channel.indexOf(value) === 0,
    },
    {
      title: 'Razón social',
      dataIndex: 'social_fact',
      key: 'social_fact',
      filters: socialDelivery,
      onFilter: (value, record) => record.social_fact.includes(value) === true,
    },
    {
      title: 'Razón social facturación',
      dataIndex: 'social_reason_fact',
      key: 'social_reason_fact',
      filters: socialFactDelivery,
      onFilter: (value, record) => record.social_reason_fact.includes(value) === true,
    },
    {
      title: 'Listado reservas',
      dataIndex: 'bookings',
      key: 'bookings',
      width: 200,
      className: 'delivery-bookings',
      filters: bookingsDelivery,
      filterSearch: true,
      onFilter: (value, record) => record.bookings.includes(value) === true,
      render: (element) => element.length > 4 ?
        element.map((id, i, row) => {
          if (i < 3) {
            return <Tag className="simple">{element[i]}</Tag>
          } else if(i === 4) {
            return <Tooltip color="#fff" placement="right" title={
              element.map((ide, index, rowe) => {
                if (index > 2) {
                  return <Tag className="simple">{element[index]}</Tag>
                }
              })
            }>
              <Tag className="simple">{ "+" + (element.length - 3).toString() }</Tag>
            </Tooltip>
          }
        })
      : element.map((id, i, row) =>
        <Tag className="simple">{element[i]}</Tag>
        )
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Importe total',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    },
    {
      title: 'Acciones',
      dataIndex: 'delivery_download',
      key: 'delivery_download',
      align: 'center',
      render: (text, record) =>
        <div style={{display: 'flex', gap: 5, justifyContent: 'center'}}>
          <Tooltip title="Forzar realbaranear">
            <Button
              style={{padding: 6}}
              className="main-btn radius-circle"
              onClick={() => recreateDelivery(record.delivery_id)}
            ><Replay style={{fill: '#FFF'}} /></Button>
          </Tooltip>
          <Tooltip title="Descargar albarán">
            <Button
              style={{padding: 6}}
              className="main-btn radius-circle"
              onClick={() => openInfoDelivery(record.delivery_id)}
            ><Download style={{fill: '#FFF'}} /></Button>
          </Tooltip>
          <Tooltip title="Generar factura">
            <Popconfirm
              placement="left"
              icon=""
              title={`¿Quieres generar la factura del albarán ${record.delivery_id}?`}
              onConfirm={() => generateFact(record.delivery_id)}
              okText="Si"
              cancelText="No"
            >
              <Button
                style={{padding: 6}}
                className="main-btn radius-circle"
                //onClick={() => generateFact(record.delivery_id)}
              ><Receipt style={{fill: '#FFF'}} /></Button>
            </Popconfirm>
          </Tooltip>
        </div>
    },
  ]

  return (
    <>
      <Loader controller={isLoading} />
      <DeliveryModal
        open={isModalDelivery}
        delivery={deliveryData}
        closeInfoDeliveryFunc={closeInfoDelivery}
      />
      <GenerateDelivery
        open={isModalGenerateDelivery}
        closeGenerateDeliveryFunc={closeGenerateDelivery}
        generateDelivery={generateDelivery}
      />
      <Row
        justify={'space-between'}
        style={{marginBottom: 20}}
      >
        <Col span={8}>
          <Form
            className="formSerachBooking"
            form={formSearch}
            onFinish={(values) => searchOnTable(values)}
            style={{marginRight: 20}}
          >
            <Form.Item
              name="text"
              style={{marginBottom: 0}}
            >
              <Input
                placeholder="Research..."
              />
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0}}
            >
              <Button
                className="iconCircle"
                htmlType="submit"
              ><SearchOutlined className="white" /></Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}></Col>
        <Col span={8} style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 8}}>
          <Button
            type="primary"
            shape="round"
            size='large'
            className="button-rounded-main"
            onClick={() => setIsModalGenerateDelivery(true)}
            style={{marginRight: 6}}
          >
            <p>Generar albarán mediante reserva</p>
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={dataTable} 
        bordered
        //onChange={onChange}
        className="mainTable"
        //onRow={(record, rowIndex) => { return {onClick: event => console.log(record, rowIndex, event)}}}
        rowKey={record => record.delivery_id + "-" + Date.now()}
        //rowKey={record => console.log(record.delivery_id + "-" + Date.now())}
      />
    </>
  )
}