import React, { useRef, useState } from "react";
import { Modal, Button, Input, Form, Select, Space, Alert } from "antd";
import DebounceSelect from "../DebounceSelect";
import apiManager from "../../api/apiManager";

export default function GenerateDelivery(props) {

  const formRef = useRef()
  const [form] = Form.useForm()
  const [value, setValue] = useState([])
  const [options, setOptions] = useState([])
  const [showError, setShowError] = useState(false)

  const generateDelivery = (values) => {
    //console.log(values.bookingId[0].value)
    props.generateDelivery(values.bookingId[0].value)
    setValue([])
    form.setFieldValue("bookingId", [])
  }

  async function fetchUserList(value) {
    //console.log(value)
    let listBookings = []
    let listBookingsCall = await apiManager.listBookings(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => { return res.data.data })
    for (const booking of listBookingsCall) {
      if (booking.identifier.includes(value.toUpperCase()) && value !== "") {
        listBookings.push({
          label: booking.identifier,
          value: booking.identifier,
        })
      }
    }
    if (listBookings.length === 0) {
      setShowError(true)
    } else {
      setShowError(false)
    }
    return listBookings
  }

  const closeModal = () => {
    setValue([])
    form.setFieldValue("bookingId", [])
    props.closeGenerateDeliveryFunc()
  }

  return (
    <Modal
      onCancel={() => closeModal()}
      centered={true}
      open={props.open}
      width={300}
      destroyOnClose={true}
      footer={null}
    >
      <Form
        form={form}
        ref={formRef}
        style={{
          marginTop: 25,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: !showError ? 20 : 5
        }}
        onFinish={generateDelivery}
      >
        <Form.Item
          name="bookingId"
          style={{marginBottom: 0, width: '100%'}}
          rules={[
            {
              required: true,
              message: 'Un identifiant de Booking est requis',
            },
          ]}
        >
          {/* <Input placeholder="Identificador reserva" style={{textAlign: 'center'}} /> */}
          {/* <Select
            showSearch
            options={[]}
            style={{width: '100%'}}
            placeholder="Sélectionnez une Booking"
            filterOption={(input, option) =>
              (option.label).toLowerCase().includes(input.toLowerCase())
            }
          /> */}
          <DebounceSelect
            mode="multiple"
            value={value}
            placeholder="Sélectionnez une Booking"
            fetchOptions={fetchUserList}
            onChange={(newValue) => {
              setValue(newValue)
            }}
            {...(value.length > 0 && {open: false})}
            style={{
              width: '100%'
            }}
          />
        </Form.Item>
        {
          showError ?
            <p style={{width: '100%', color: 'red'}}>Aucune correspondance de Booking</p>
          : <></>
        }
        {/* <Alert message="Aucune Booking trouvée" type="error" /> */}
        <Form.Item style={{marginBottom: 0}}>
          <Button className="main-btn" type="primary" htmlType="submit">Generar albarán</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}