import React, { useState, useEffect } from "react";
import { Table, DatePicker, Form, Button, Input, Tag, Modal, Divider, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Lock, Edit } from "@mui/icons-material";
import moment from "moment";
import dayjs from "dayjs";
import BookingInfo from "../Reserva/BookingInfo";
import BookingCreate from "../Reserva/BookingCreate";
import Loader from "../Loader/Loader";

import apiManager from "../../api/apiManager";
import "./searchbookingtable.css";
import styles from "./tablebooking.module.css";

import { useUser } from "../../api/context/userContext";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function SearchBookingTableB2B(props) {

  const today = moment(Date.now())
  const dateFormat = "DD-MM-YYYY"
  const { RangePicker } = DatePicker
  const { userData } = useUser()

  const [dataTable, setDataTable] = useState([]);
  const [paginationItems, setPaginationItems] = useState(50)
  const [channelList, setChannelList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false)
  const [bookingIdSelected, setBookingIdSelected] = useState({})
  const [dateValue, setDateValue] = useState(dayjs(Date.now()).format('DD-MM-YYYY'));
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [formBooking] = Form.useForm()
  const [formStart] = Form.useForm()
  const [formSearch] = Form.useForm()

  useEffect(() => {
    let variables = {
      textSearch: undefined,
      startDate: today.format('DD-MM-YYYY'),
      endDate: today.add(1, 'days').format('DD-MM-YYYY'),
      createStartDate: undefined,
      createEndDate: undefined,
      centerId: JSON.parse(localStorage.getItem("activeCenter")).id
    }
    formStart.setFieldValue('dates', [dayjs(Date.now()), dayjs(Date.now())])
    updateTableData(variables)
    /* apiManager.bookingListTable(variables)
    .then((res) => {
      console.log(res.data.data)
      setDataTable(res.data.data)
    }) */
    setChannelList([])
    apiManager.getChannels(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      res.data.data.map((channel) => {
        setChannelList(current => [...current, {
          'text': channel.title,
          'value': channel.title,
          'idChannel': channel.id
        }])
      })
    })
    apiManager.isAdmin(localStorage.getItem("token"))
    .then((res) => {
      setIsAdmin(res.data.data[0].user_is_admin)
    })
  }, [])

  const updateTableData = (variables) => {
    setIsLoading(true)
    apiManager.bookingListTable(variables, true, userData.user_token)
    .then((res) => {
      //console.log(res.data.data)
      setDataTable(res.data.data)
      setIsLoading(false)
    })
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  function periodEditB2B(values) {
    let today = new Date()
    today.setHours(0,0,0,0)
    let startDateFrag = values.start_date.split("-")
    let startDate = new Date(`${startDateFrag[1]}/${startDateFrag[0]}/${startDateFrag[2]}`)
    startDate.setHours(0,0,0,0)
    let diff = startDate.getTime() - today.getTime()
    let dayDiff = diff / (1000 * 60 * 60 * 24)
    return (
      dayDiff > parseInt(userData.edition_period) ?
        <Edit style={{fontSize: 14}} />
      : <></>
    )
  }

  const columns = [
    {
      title: '',
      dataIndex: 'creator',
      width: 40,
      render: (text, values) => <span>{ text === userData.name ? periodEditB2B(values) : "" }</span>,
    },
    {
      title: 'Reserva',
      dataIndex: 'booking_id',
      /* key: 'created_hour',
      sorter: (a, b) => a.created_hour.localeCompare(b.created_hour), */
      //sorter: (a, b) => a.booking_id.localeCompare(b.booking_id),
    },
    {
      title: 'Creador',
      dataIndex: 'creator'
    },
    {
      title: 'First name et nom',
      dataIndex: 'client'
    },
    {
      title: 'Total PVP',
      dataIndex: 'base_price',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>,
      sorter: (a, b) => Number(a.base_price).toFixed(2) - Number(b.base_price).toFixed(2),
    },
    {
      title: 'Total facturado',
      dataIndex: 'total_price',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>,
      sorter: (a, b) => Number(a.total_price).toFixed(2) - Number(b.total_price).toFixed(2),
    },
    {
      title: 'Comisión',
      dataIndex: 'comision',
      render: (a, b) => <span>{ Number(b.base_price - b.total_price).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>,
      sorter: (a, b) => Number(a.base_price - a.total_price).toFixed(2) - Number(b.base_price - b.total_price).toFixed(2),
    },
    {
      title: 'Check-in date',
      dataIndex: 'start_date',
    },
    {
      title: 'Check-out date',
      dataIndex: 'end_date',
    },
    {
      title: 'Booking date',
      dataIndex: 'created_date',
    },
    {
      title: 'Partners',
      dataIndex: 'channel',
      filters: channelList,
      onFilter: (value, record) => record.channel.indexOf(value) === 0,
    }
  ]

  const summaryTable = (pageData) => {
    if (dataTable.length > 0) {
      let totalPrice = 0
      let totalPending = 0
      let totalPVP = 0

      pageData.forEach(({ total_price, total_pending, base_price }) => {
        totalPrice = totalPrice + Number(total_price)
        totalPending = totalPending + Number(total_pending)
        totalPVP = totalPVP + Number(base_price)
      })
      
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={4}><b>TOTAL</b></Table.Summary.Cell>
            <Table.Summary.Cell align="right"><b>{ Number(totalPVP).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
            <Table.Summary.Cell align="right"><b>{ Number(totalPrice).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
            <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    //console.log('params', pagination, filters, sorter, extra);
    if (pagination.pageSize !== paginationItems) {
      setPaginationItems(pagination.pageSize)
    }
  };

  const dateBookingUpdate = (values) => {
    let text = formSearch.getFieldValue('text')
    let variables = {
      textSearch: text !== undefined ? text : undefined,
      startDate: undefined,
      endDate: undefined,
      createStartDate: undefined,
      createEndDate: undefined,
      centerId: JSON.parse(localStorage.getItem("activeCenter")).id
    }
    formStart.resetFields()
    if (values.dates !== null) {
      variables.createStartDate = values.dates[0].format('DD-MM-YYYY')
      variables.createEndDate = values.dates[1].add(1, 'days').format('DD-MM-YYYY')
      updateTableData(variables)
    } else {
      updateTableData(variables)
    }
  }

  const dateStartUpdate = (values) => {
    let text = formSearch.getFieldValue('text')
    let variables = {
      textSearch: text !== undefined ? text : undefined,
      startDate: undefined,
      endDate: undefined,
      createStartDate: undefined,
      createEndDate: undefined,
      centerId: JSON.parse(localStorage.getItem("activeCenter")).id
    }
    formBooking.resetFields()
    if (values.dates !== null) {
      variables.startDate = values.dates[0].format('DD-MM-YYYY')
      variables.endDate = values.dates[1].add(1, 'days').format('DD-MM-YYYY')
      updateTableData(variables)
    } else {
      updateTableData(variables)
    }
  }

  const searchUpdate = (values) => {
    let datesBooking = formBooking.getFieldsValue()
    let datesStart = formStart.getFieldsValue()
    let variables = {
      textSearch: values.text !== "" ? values.text : undefined,
      startDate: undefined,
      endDate: undefined,
      createStartDate: undefined,
      createEndDate: undefined,
      centerId: JSON.parse(localStorage.getItem("activeCenter")).id
    }
    if (Array.isArray(datesBooking.dates)) {
      variables.createStartDate = datesBooking.dates[0].format('DD-MM-YYYY')
      variables.createEndDate = datesBooking.dates[1].add(1, 'days').format('DD-MM-YYYY')
    }
    if (Array.isArray(datesStart.dates)) {
      variables.startDate = datesStart.dates[0].format('DD-MM-YYYY')
      variables.endDate = datesStart.dates[1].add(1, 'days').format('DD-MM-YYYY')
    }
    updateTableData(variables)
  }

  const closeBookingInfoModal = () => {
    let datesBooking = formBooking.getFieldsValue()
    let datesStart = formStart.getFieldsValue()
    let text = formSearch.getFieldValue('text')
    let variables = {
      textSearch: text !== "" ? text : undefined,
      startDate: undefined,
      endDate: undefined,
      createStartDate: undefined,
      createEndDate: undefined,
      centerId: JSON.parse(localStorage.getItem("activeCenter")).id
    }
    if (Array.isArray(datesBooking.dates)) {
      variables.createStartDate = datesBooking.dates[0].format('DD-MM-YYYY')
      variables.createEndDate = datesBooking.dates[1].add(1, 'days').format('DD-MM-YYYY')
    }
    if (Array.isArray(datesStart.dates)) {
      variables.startDate = datesStart.dates[0].format('DD-MM-YYYY')
      variables.endDate = datesStart.dates[1].add(1, 'days').format('DD-MM-YYYY')
    }
    updateTableData(variables)
    setIsModalBookingOpen(false)
  }

  const openBookingInfo = (info, rowIndex, event) => {
    let channel = channelList.find((o) => o.value === info.channel)
    info.channel_id = channel.idChannel
    //console.log(info)
    setBookingIdSelected(info)
    setIsModalBookingOpen(true)
  }

  const closeFinishBooking = (date, channel, id) => {
    setIsModalOpen(false)
    let info = {
      id: id,
      channel_id: channel
    }
    let newDate = dayjs(date.split("-").reverse().join("-"))
    let variables = {
      textSearch: undefined,
      startDate: newDate,
      endDate: newDate,
      createStartDate: undefined,
      createEndDate: undefined,
      centerId: JSON.parse(localStorage.getItem("activeCenter")).id
    }
    updateTableData(variables)
    setBookingIdSelected(info)
    setIsModalBookingOpen(true)
  }

  const confirmCancelResume = (texto) => {
    confirmAlert({
      title: 'You are sure you want ' + texto + '?',
      message: 'You will lose all activity data.',
      buttons: [
        {
          label: 'Back',
          onClick: () => setIsModalOpen(false),
          className: 'close-btn',
        },
        {
          label: 'Cancel',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Modal
        maskClosable={false}
        onCancel={() => confirmCancelResume('cerrar la ventana')}
        centered={true}
        open={isModalOpen}
        width={1140}
        destroyOnClose={true}
        cancelText="Cancel booking"
        footer={null}
      >
        <BookingCreate
          closeFinishBooking={closeFinishBooking}
        />
        <Divider />
        <Row justify="end">
          <Col>
            <Button
              onClick={() => confirmCancelResume('cancelar la reserva')}
              className="basic-btn"
            >Cancel booking</Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeBookingInfoModal()}
        centered={true}
        open={isModalBookingOpen}
        width={1000}
        destroyOnClose={true}
        cancelText="Back"
        footer={null}
      >
        <BookingInfo
          bookingInfoData={bookingIdSelected}
          bookingClose={closeBookingInfoModal}
          selectedDate={dateValue}
        />
      </Modal>
      <div className="bigCalendar-container" style={{marginBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
        <div>
          <Form
            className="formSerachBooking"
            form={formBooking}
            onFinish={(values) => dateBookingUpdate(values)}
          >
            <Form.Item
              label="Booking"
              name="dates"
              /* rules={[
                {
                  required: true,
                  message: "Required Field"
                },
              ]} */
            >
              <RangePicker
                format={dateFormat}
                onChange={(e, a) => dateBookingUpdate(formBooking.getFieldsValue()) }
                //onChange={(e, a) => console.log(a)}
                placeholder={["Desde", "Hasta"]}
              />
            </Form.Item>
            {/* <Form.Item>
              <Button
                className="iconCircle"
                htmlType="submit"
              ><SearchOutlined className="white" /></Button>
            </Form.Item> */}
          </Form>
          <Form
            className="formSerachBooking"
            form={formStart}
            onFinish={() => dateStartUpdate()}
          >
            <Form.Item
              label="Entrance"
              name="dates"
            >
              <RangePicker
                format={dateFormat}
                onChange={(e, a) => dateStartUpdate(formStart.getFieldsValue()) }
                placeholder={["Check-in date", "Check-out date"]}
              />
            </Form.Item>
          </Form>
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
          <Button
            type="primary"
            shape="round"
            size='large'
            className={styles.button}
            style={{marginBottom: 15}}
            onClick={() => showModal()}
          >
            <p><span>+</span>Booking</p>
          </Button>
          <Form
            className="formSerachBooking"
            form={formSearch}
            onFinish={(values) => searchUpdate(values)}
          >
            <Form.Item
              name="text"
            >
              <Input
                placeholder="Research..."
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="iconCircle"
                htmlType="submit"
              ><SearchOutlined className="white" /></Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={dataTable} 
        bordered
        onChange={onChange}
        className="mainTable"
        rowClassName={(record, index) => record.creator === userData.name ? "owner-b2b" : ""}
        pagination={
        {
          total: dataTable.length,
          pageSize: paginationItems,
          //defaultPageSize: 50,
          showSizeChanger: true,
          onChange: (e) => console.log(e),
          pageSizeOptions: ['50', '100', '500', dataTable.length],
          locale: { items_per_page: ""},
          showTotal: (total) => `${total} ${total < 2 ? "elemento" : "elementos"}`,
        }
        }
        summary={pageData => summaryTable(pageData)}
        onRow={(record, rowIndex) => ({onClick: event => openBookingInfo(record, rowIndex, event)})}
      />
    </>
  )
}