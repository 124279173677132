import React, {useState, useEffect, useRef} from "react";
import { Table } from "antd";
import apiManager from "../../api/apiManager";
import { CSVLink } from "react-csv";

export default function TableTreasuryBook(props) {

  const csvLink = useRef()
  const [dataTable, setDataTable] = useState([])
  const [socialReason, setSocialReason] = useState([])

  useEffect(() => {
    //console.log(props)
    if (props.paymentMethod !== "") {
      apiManager.bookTreasury(
        JSON.parse(localStorage.getItem("activeCenter")).id,
        props.paymentMethod,
        props.dates.length > 0 ? props.dates[0] : null,
        props.dates.length > 0 ? props.dates[1] : null
      )
      .then((res) => {
        let result = res.data.data
        console.log(result)
        props.dataOfTable(result)
        filterSocialReason(result)
        setDataTable(result)
      })
    }
  }, [])

  useEffect(() => {
    if (props.download > 0) {
      csvLink.current.link.click()
      //console.log(csvLink)
    }
  }, [props.download])

  const filterSocialReason = (elements) => {
    setSocialReason([])
    let list = []
    elements.map(item => {
      if (list.some((e) => e.value === item.social_reason_fact) || item.social_reason_fact === "") {
        
      } else {
        list.push({value: item.social_reason_fact})
        setSocialReason(current => [...current, {
          'text': item.social_reason_fact,
          'value': item.social_reason_fact
        }])
      }
    })
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date_pm',
      key: 'date_pm',
    },
    {
      title: 'Método de pago',
      dataIndex: 'pm',
      key: 'pm',
    },
    {
      title: 'Razón social',
      dataIndex: 'social_reason_fact',
      key: 'social_reason_fact',
      filters: socialReason,
      onFilter: (value, record) => record.social_reason_fact.includes(value) === true,
    },
    {
      title: 'Booking',
      dataIndex: 'booking_identifier',
      key: 'booking_identifier',
    },
    {
      title: 'Número factura',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
    },
    {
      title: 'Amount',
      dataIndex: 'pm_import',
      key: 'pm_import',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    }
  ]

  const headersCSV = [
    {
      label: "Fecha",
      key: "date_pm"
    },
    {
      label: "Método de pago",
      key: "pm"
    },
    {
      label: "Razón social",
      key: "social_reason_fact"
    },
    {
      label: "Booking",
      key: "booking_identifier"
    },
    {
      label: "Número factura",
      key: "invoice_id"
    },
    {
      label: "Amount",
      key: "pm_import"
    },
  ]

  return (
    <>
      <Table
        showHeader={props.paymentMethod !== ""}
        columns={columns}
        dataSource={dataTable} 
        bordered
        className="mainTable"
      />
      <CSVLink
        data={dataTable}
        headers={headersCSV}
        separator={","}
        style={{display: 'none'}}
        ref={csvLink}
        target='_blank'
        filename={`tb_${props.paymentMethod}_${Date.now()}.csv`}
      >
        Descargar CSV
      </CSVLink>
    </>
  )
}