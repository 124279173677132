import React from "react";
import { useEffect, useState } from "react";
import { Table } from "antd";

import apiManager from "../../api/apiManager";

export default function TableActivitiesReports(props) {

  const [activitiesParentList, setActivitiesParentList] = useState([])
  const [activtiesList, setActivtiesList] = useState([])

  useEffect(() => {
    let listActParent = []
    let listAct = []
    setActivitiesParentList([])
    setActivtiesList([])
    props.data.map((element) => {
      if (!listActParent.includes(element.activity_parent)) {
        listActParent.push(element.activity_parent)
        setActivitiesParentList(current => [...current, {
          'text': element.activity_parent,
          'value': element.activity_parent
        }])
      }
      if (!listAct.includes(element.activity)) {
        listAct.push(element.activity)
        setActivtiesList(current => [...current, {
          'text': element.activity,
          'value': element.activity
        }])
      }
    })
  }, [])

  const columns = [
    {
      title: 'Family of activities',
      dataIndex: 'activity_parent',
      filters: activitiesParentList,
      onFilter: (value, record) => record.activity_parent.indexOf(value) === 0,
    },
    {
      title: 'Activité',
      dataIndex: 'activity',
      filters: activtiesList,
      onFilter: (value, record) => record.activity.indexOf(value) === 0,
    },
    {
      title: 'Pers.',
      dataIndex: 'num_pax',
      align: 'right',
    },
    {
      title: 'Amount',
      dataIndex: 'price',
      align: 'right',
      render: (text) => <span className={Number(text) < 0 ? "red" : ""}>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    }
  ]

  return (
    <>
      <Table
        className="activitiesReportsTable"
        bordered
        columns={columns}
        dataSource={props.data}
        pagination={false}
      />
    </>
  )
}