import React, {useState, useEffect, useRef} from "react";
import { DatePicker, Button, Row, Col, Select, Tooltip, Form, Input } from "antd";
import { Refresh, Download } from "@mui/icons-material";
import { SearchOutlined } from "@ant-design/icons"
import TableInvoiceBook from "../../componentes/Tabla/TableInvoiceBook";
import apiManager from "../../api/apiManager";

export default function InvoiceBook() {

  useEffect(() => {
    initFuncion()
  }, [])

  const childRef = useRef()
  const dateFormat = "DD-MM-YYYY"
  const { RangePicker } = DatePicker

  const [dates, setDates] = useState([])
  const [datesSelected, setDatesSelected] = useState([])
  const [socialReasonList, setSocialReasonList] = useState([])
  const [socialReason, setSocialReason] = useState("")
  const [socialReasonText, setSocialReasonText] = useState("")
  const [socialReasonSelected, setSocialReasonSelected] = useState(null)
  const [download, setDownload] = useState(0)
  const [compKey, setCompKey] = useState(0)
  const [dataLength, setDataLength] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [formSearch] = Form.useForm()

  const initFuncion = () => {
    setSocialReasonList([])
    apiManager.getSocialReasons(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      result.map((sReason) => {
        setSocialReasonList(current => [...current, {
          'text': sReason.title,
          'value': sReason.title,
          'id': sReason.id
        }])
      })
    })
  }

  const findForDate = (values, dates) => {
    setDatesSelected(dates)
    setDates(values)
    setDownload(0)
    setCompKey(compKey + 1)
  }

  const changeSocialReason = (value) => {
    setSocialReasonSelected(value.value)
    setSocialReason(value.id)
    setSocialReasonText(value.text)
    setDownload(0)
    formSearch.setFieldValue('text', '')
    setSearchText('')
    setDataLength(0)
    setCompKey(compKey + 1)
  }

  const refreshValues = () => {
    setSocialReasonSelected(null)
    setDatesSelected([])
    setSocialReason("")
    setDates([])
    setDownload(0)
    formSearch.setFieldValue('text', '')
    setSearchText('')
    setDataLength(0)
    setCompKey(compKey + 1)
  }

  const dataOfTable = (data) => {
    setDataLength(data.length)
  }

  return (
    <>
      <Row
        justify={'space-between'}
        style={{marginBottom: 20}}
      >
        <Col
          span={8}
          className="menu-col"
          style={{display: "flex", justifyContent: "left", alignItems: "center", gap: 8}}
        >
          <Select
            style={{ width: 200 }}
            placeholder="Selecciona una razón social"
            onChange={(e, a) => changeSocialReason(a)}
            value={socialReasonSelected}
            options={socialReasonList}
          />
          <RangePicker
            format={dateFormat}
            onChange={(e, a) => findForDate(a, e) }
            placeholder={["Desde", "Hasta"]}
            value={datesSelected}
            disabled={socialReason === ""}
          />
          <Button
            disabled={socialReason === "" && dates.length === 0}
            onClick={() => refreshValues()}
          ><Refresh /></Button>
          <Tooltip title="Descargar CSV">
            <Button
              onClick={() => setDownload((download) => download + 1)}
              disabled={dataLength === 0}
            >
              <Download />
            </Button>
          </Tooltip>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Form
            className="formSerachBooking"
            form={formSearch}
            onFinish={(values) => setSearchText(values)}
            style={{justifyContent: 'flex-end'}}
          >
            <Form.Item
              name="text"
              style={{marginBottom: 0}}
            >
              <Input
                placeholder="Research..."
                disabled={dataLength === 0}
              />
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0}}
            >
              <Button
                className="iconCircle"
                htmlType="submit"
                disabled={dataLength === 0}
              ><SearchOutlined className="white" /></Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <TableInvoiceBook
        ref={childRef}
        key={compKey}
        socialReason={socialReason}
        socialReasonText={socialReasonText}
        dates={dates}
        download={download}
        dataOfTable={dataOfTable}
        searchOnTable={searchText}
      />
    </>
  )
}