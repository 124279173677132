import React from "react";
import { useEffect, useState, useRef } from "react";
import { Table, Tag, DatePicker, Col, Row, Button, Select, Form, Modal, Divider, Input, Badge, TimePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { CheckCircleTwoTone, CloseCircleTwoTone, LeftOutlined, RightOutlined, SearchOutlined, ContainerFilled, DeleteOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import styles from "./tablebooking.module.css";
import BookingCreate from "../Reserva/BookingCreate";
import BookingInfo from "../Reserva/BookingInfo";
import { Lock, Edit } from "@mui/icons-material";
import Loader from "../Loader/Loader";

import apiManager from "../../api/apiManager";
import "./tablebooking.css";

import { useUser } from "../../api/context/userContext";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function TableBookingB2B(props) {

  const today = moment(Date.now()).format('DD-MM-YYYY');
  const dateFormat = 'DD-MM-YYYY';
  const dateSelected = useRef();
  const formRef = useRef();
  const { TextArea } = Input
  const { userData } = useUser()

  useEffect(() => {
    getBookingGrouped(today, JSON.parse(localStorage.getItem("activeCenter")).id)
    setChannelList([])
    apiManager.getChannels(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      res.data.data.map((channel) => {
        setChannelList(current => [...current, {
          'text': channel.title,
          'value': channel.title
        }])
      })
    })
    apiManager.isAdmin(localStorage.getItem("token"))
    .then((res) => {
      setIsAdmin(res.data.data[0].user_is_admin)
    })
    setWeatherList([])
    apiManager.getListWeather(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      //console.log(res.data.data)
      res.data.data.map((item) => {
        setWeatherList(current => [...current, {
          'label': item.title,
          'value': item.id
        }])
      })
    })
  }, [])

  const [dataTableSimple, setDataTableSimple] = useState([])
  const [dataTableGrouped, setDataTableGrouped] = useState([])
  const [dataTableBase, setDataTableBase] = useState([])
  const [viewTable, setViewTable] = useState('grouped')
  const [dateValue, setDateValue] = useState(dayjs(Date.now()).format('DD-MM-YYYY'))
  const [noteDate, setNoteDate] = useState(dayjs(dateValue.split("-").reverse().join("-")))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false)
  const [isModalAnnotation, setIsModalAnnotation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationItems, setPaginationItems] = useState(50)
  const [channelList, setChannelList] = useState([])
  const [bookingIdSelected, setBookingIdSelected] = useState({})
  const [isAdmin, setIsAdmin] = useState(false)
  const [notesList, setNotesList] = useState([])
  const [weatherList, setWeatherList] = useState([])
  const [filterFamiliesTable, setFilterFamiliesTable] = useState([])
  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()
  const [formNotesDay] = Form.useForm()

  let dateVvalue = dayjs();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getBookingSimple = (date, centerId) => {
    setIsLoading(true)
    getAnotationsDay(date, centerId)
    apiManager.getBookingSimple(date, centerId, true, localStorage.getItem("token"))
    .then((res) => {
      //console.log(res.data.data)
      let result = res.data.data
      setDataTableSimple(result)
      setDataTableBase(result)
      setIsLoading(false)
      if (paginationItems !== 50 && paginationItems !== 100 && paginationItems !== 500) {
        if (paginationItems !== result.length) {
          setPaginationItems(50)
          setPaginationItems(result.length)
        }
      }
    })
  }

  const getBookingGrouped = (date, centerId) => {
    setIsLoading(true)
    getAnotationsDay(date, centerId)
    apiManager.getBookingGrouped(date, centerId, true, localStorage.getItem("token"))
    .then((res) => {
      let result = res.data.data
      //console.log(result)
      filterFamiliesTableFunc(result)
      setDataTableGrouped(result)
      setDataTableBase(result)
      setIsLoading(false)
    })
  }

  const filterFamiliesTableFunc = (elements) => {
    setFilterFamiliesTable([])
    let list = []
    elements.map(activity => {
      let activityName = activity.activity.split(" - ").shift()
      if (list.some((e) => e.value === activityName)) {
        
      } else {
        list.push({value: activityName})
        setFilterFamiliesTable(current => [...current, {
          'text': activityName,
          'value': activityName
        }])
      }
    })
  }

  const changeTableDay = (newDate, newDateFormated) => {
    setDateValue(newDateFormated)
    if (viewTable === 'simple') {
      getBookingSimple(newDateFormated, JSON.parse(localStorage.getItem("activeCenter")).id);
      dateVvalue = newDate;
    } else {
      getBookingGrouped(newDateFormated, JSON.parse(localStorage.getItem("activeCenter")).id);
    }
    console.log(newDateFormated)
  }

  function periodEditB2B(values) {
    let today = new Date()
    today.setHours(0,0,0,0)
    let startDateFrag = values.booking_activity_date.split("-")
    let startDate = new Date(`${startDateFrag[1]}/${startDateFrag[0]}/${startDateFrag[2]}`)
    startDate.setHours(0,0,0,0)
    let diff = startDate.getTime() - today.getTime()
    let dayDiff = diff / (1000 * 60 * 60 * 24)
    return (
      dayDiff > parseInt(userData.edition_period) ?
        <Edit style={{fontSize: 14}} />
      : <></>
    )
  }

  const columnsSimple = [
    {
      title: '',
      dataIndex: 'creator',
      width: 40,
      render: (text, values) => <span>{ text === userData.name ? periodEditB2B(values) : "" }</span>,
    },
    {
      title: 'Hour',
      dataIndex: 'created_hour',
      key: 'created_hour',
      width: 80,
      align: 'center',
      sorter: (a, b) => a.created_hour.localeCompare(b.created_hour),
    },
    {
      title: 'Activité',
      dataIndex: 'activity',
      key: 'activity'
    },
    {
      title: 'Booking info',
      dataIndex: 'client_info',
      key: 'client_info',
    },
    {
      title: 'Creador',
      dataIndex: 'creator',
      key: 'creator',
    },
    {
      title: 'ID',
      dataIndex: 'identifier',
      key: 'identifier'
    },
    {
      title: 'Pers.',
      dataIndex: 'pax',
      key: 'pax',
      align: 'center',
      width: 50
    },
    {
      title: 'Total PVP',
      dataIndex: 'base_price',
      key: 'base_price',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    },
    {
      title: 'Booking date',
      dataIndex: 'created_date',
      key: 'created_date',
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
    },
  ]

  const columnsGrouped = [
    {
      title: 'Hour',
      dataIndex: 'turn_info',
      key: 'turn_info'
    },
    {
      title: 'Activité',
      dataIndex: 'activity',
      key: 'activity',
      filters: filterFamiliesTable,
      onFilter: (value, record) => record.activity.includes(value) === true,
    },
    {
      title: 'Places occupées',
      dataIndex: 'bussy_places',
      key: 'bussy_places'
    },
    {
      title: 'Free places',
      dataIndex: 'av_places',
      key: 'av_places'
    },
    {
      title: 'Recursos ocupadas',
      dataIndex: 'bussy_resources',
      key: 'bussy_resources'
    },
    {
      title: 'Recursos libres',
      dataIndex: 'av_resources',
      key: 'av_resources'
    },
    {
      title: 'Total Bookings',
      dataIndex: 'num_booking',
      key: 'num_booking'
    },
  ]

  const summaryTable = (pageData) => {
    if (viewTable === "simple") {
      if (dataTableSimple.length > 0 && isAdmin) {
        let totalPax = 0;
        let totalPrice = 0;

        pageData.forEach(({ pax, price }) => {
          totalPax += Number(pax);
          totalPrice += Number(price);
        });
        
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={5}><b>TOTAL</b></Table.Summary.Cell>
              <Table.Summary.Cell align="center"><b>{totalPax}</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{ Number(totalPrice).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
              <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }
    } else {
      if (dataTableGrouped.length > 0 && isAdmin) {
        let avPlaces = 0;
        let avResources = 0;
        let bussyPlaces = 0;
        let bussyResources = 0;
        let totalBookings = 0;

        pageData.forEach(({ av_places, av_resources, bussy_places, bussy_resources, num_booking }) => {
          avPlaces += av_places === "" ? 0 : av_places;
          avResources += av_resources === "" ? 0 : av_resources;
          bussyPlaces += bussy_places === "" ? 0 : bussy_places;
          bussyResources += bussy_resources === "" ? 0 : bussy_resources;
          totalBookings += num_booking;
        });
        
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2}><b>TOTAL</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{bussyPlaces}</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{avPlaces}</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{bussyResources}</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{avResources}</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{totalBookings}</b></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }
    }
  }

  const viewChange = (value) => {
    setIsLoading(true)
    if (value === "simple") {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
    setViewTable(value)
  }

  const onChange = (pagination, filters, sorter, extra) => {
    //console.log('params', pagination, filters, sorter, extra);
    if (pagination.pageSize !== paginationItems) {
      setPaginationItems(pagination.pageSize)
    }
  };

  const confirmCancelResume = (texto) => {
    confirmAlert({
      title: 'You are sure you want ' + texto + '?',
      message: 'You will lose all activity data.',
      buttons: [
        {
          label: 'Back',
          onClick: () => closeModal(),
          className: 'close-btn',
        },
        {
          label: 'Cancel',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const closeFinishBooking = (date, channel, id) => {
    closeModal()
    let info = {
      id: id,
      channel_id: channel
    }
    let fechaNueva = moment(date.split("-").reverse().join("-"));
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', dayjs(date.split("-").reverse().join("-")));
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      setBookingIdSelected(info)
      setIsModalBookingOpen(true)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      setBookingIdSelected(info)
      setIsModalBookingOpen(true)
    }
    formSearch.setFieldValue("text", "")
  }

  const prevDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(-1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
  }

  const nextDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
  }

  const openBookingInfo = (info, rowIndex, event) => {
    //console.log(info)
    if (event.target.classList.contains('arrived') || event.target.nodeName === "path") {
      clientArrived(info.booking_activity_id, !info.client_arrived)
      /* confirmAlert({
        title: `${ !info.client_arrived ? "¿Ha llegado el cliente para la actividad" : "¿No ha llegado el cliente para la actividad" } ${info.identifier}?`,
        buttons: [
          {
            label: `${ !info.client_arrived ? "Aun no" : "Ha llegado" }`,
            //onClick: () => closeModal(),
            className: 'close-btn',
          },
          {
            label: `${ !info.client_arrived ? "Ha llegado" : "Aun no" }`,
            onClick: () => clientArrived(info.booking_activity_id, !info.client_arrived),
            className: 'confirm-btn',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: "overlay-confirm-suprimir"
      }) */
    } else {
      /* console.log(info)
      console.log(rowIndex)
      console.log(event) */
      setBookingIdSelected(info)
      setIsModalBookingOpen(true)
    }
  }

  const closeBookingInfoModal = () => {
    let fechaNueva = form.getFieldValue('datepicker')
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
    formSearch.setFieldValue("text", "")
    setIsModalBookingOpen(false)
  }

  const clientArrived = (id, newState) => {
    let data = {
      booking_activity_id: id,
      status: newState
    }
    apiManager.clientArrived(data)
    .then((res) => {
      console.log(res.data.data)
      let fechaNueva = form.getFieldValue('datepicker')
      if (viewTable === 'simple') {
        getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      } else {
        getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      }
    })
  }

  const searchOnTable = (text) => {
    console.log(text)
    if (text.text !== undefined && text.text !== "") {
      //let tableData = viewTable === 'simple' ? dataTableSimple : dataTableGrouped
      let tableData = dataTableBase
      const filterResult = tableData.filter(
        (item) =>
          item.client_info
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.identifier
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.status.toLowerCase().includes(text.text.toLowerCase()) ||
          item.channel.toLowerCase().includes(text.text.toLowerCase()) ||
          item.activity.toLowerCase().includes(text.text.toLowerCase())
      )
      if (viewTable === 'simple') {
        setDataTableSimple(filterResult)
      } else {
        setDataTableGrouped(filterResult)
      }
    } else {
      console.log(dataTableBase)
      if (viewTable === 'simple') {
        setDataTableSimple(dataTableBase)
      } else {
        setDataTableGrouped(dataTableBase)
      }
    }
  }

  const closeAnnotationModal = () => {
    setIsModalAnnotation(false)
    setIsLoading(true)
    if (viewTable === "simple") {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
    formNotesDay.resetFields()
  }

  const getAnotationsDay = (date, centerId) => {
    apiManager.getAnotationsDay(date, centerId)
    .then((res) => {
      //console.log(res.data.data)
      setNotesList(res.data.data)
    })
  }

  const addNoteDay = (values) => {
    console.log(dateValue)
    let data = {
      annotation: values.note,
      weather: values.weather === undefined ? "" : values.weather,
      date: values.date.format('DD-MM-YYYY') + " " + values.time.format('HH:mm')
    }
    apiManager.addAnotationDay(data)
    .then((res) => {
      if (viewTable === 'simple') {
        getBookingSimple(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      } else {
        getBookingGrouped(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      }
      formNotesDay.resetFields()
    })
    console.log(data)
  }

  const deleteNoteDay = (id) => {
    let data = {
      dateddannotation_id: id
    }
    apiManager.deleteAnotationDay(data)
    .then((res) => {
      if (viewTable === 'simple') {
        getBookingSimple(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      } else {
        getBookingGrouped(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      }
    })
  }

  const confirmDeleteNote = (id) => {
    confirmAlert({
      title: '¿Seguro que quieres eliminar esta anotación?',
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel-btn',
        },
        {
          label: 'Confirm',
          onClick: () => deleteNoteDay(id),
          className: 'confirm-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const openModalAnotationDay = () => {
    //let todayTime = dayjs().format('HH:MM')
    formNotesDay.setFieldValue('date', dayjs(dateValue.split("-").reverse().join("-")))
    formNotesDay.setFieldValue('time', dayjs())
    setIsModalAnnotation(true)
  }

  const onSelectTimeAnotation = (value) => {
    formNotesDay.setFieldValue('time', value)
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Modal
        maskClosable={false}
        //open={isModalOpen}
        //onOk={() => closeModal()}
        onCancel={() => confirmCancelResume('cerrar la ventana')}
        centered={true}
        open={isModalOpen}
        width={1140}
        destroyOnClose={true}
        cancelText="Cancel booking"
        footer={null}
      >
        <BookingCreate
          closeFinishBooking={closeFinishBooking}
        />
        <Divider />
        <Row justify="end">
          <Col>
            <Button
              onClick={() => confirmCancelResume('cancelar la reserva')}
              className="basic-btn"
            >Cancel booking</Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeBookingInfoModal()}
        centered={true}
        open={isModalBookingOpen}
        width={1000}
        destroyOnClose={true}
        cancelText="Back"
        footer={null}
      >
        <BookingInfo
          bookingInfoData={bookingIdSelected}
          bookingClose={closeBookingInfoModal}
          selectedDate={dateValue}
        />
      </Modal>
      <Modal
        footer={null}
        open={isModalAnnotation}
        centered={true}
        maskClosable={false}
        onCancel={() => closeAnnotationModal()}
        width={700}
        destroyOnClose={true}
      >
        {
          notesList.length > 0 ?
            <>
              <div className="listNotes">
                {
                  notesList.map((note, index) =>
                    <div className="noteItem" key={`noteKey-${note.id}`}>
                      <Row align="middle">
                        <Col span={22}>
                          <p><b>Usuario</b>: { note.user_mail }</p>
                          <p><b>Date of annotation</b>: { note.created_date }</p>
                          <p><b>Clima</b>: { note.weather }</p>
                          <p><b>Anotación</b>: { note.annotation }</p>
                        </Col>
                        <Col span={2} style={{textAlign: "right"}}>
                          <Button
                            className="iconCircle deleteBtn"
                            title="Delete annotation"
                            onClick={() => confirmDeleteNote(note.id)}
                          ><DeleteOutlined className="white" /></Button>
                        </Col>
                      </Row>
                    </div>
                  )
                }
              </div>
              <Divider />
            </>
          : "" 
        }
        <Form
          form={formNotesDay}
          onFinish={() => addNoteDay(formNotesDay.getFieldsValue())}
          initialValues={{date: dayjs(dateValue.split("-").reverse().join("-")), time: dayjs()}}
          style={notesList.length < 1 ? {marginTop: 30} : {}}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="Clima"
                name="weather"
                labelCol={{span: 8}}
              >
                <Select
                  style={{ width: 120 }}
                  options={weatherList}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{display: "flex"}}>
              <Form.Item
                label="Fecha"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "Required Field"
                  },
                ]}
                wrapperCol={{offset: 0, span: 15}}
                //initialValue={dayjs(dateValue.split("-").reverse().join("-"))}
              >
                <DatePicker
                  allowClear={false}
                  //defaultValue={dayjs()}
                  //value={dayjs(dateValue.split("-").reverse().join("-"))}
                  //onChange={(e) => changeNoteDate(e)}
                  format={dateFormat}
                />
              </Form.Item>
              <Form.Item
                name="time"
                rules={[
                  {
                    required: true,
                    message: "Required Field"
                  },
                ]}
                labelCol={{offset: 0, span: 0}}
                wrapperCol={{offset: 0, span: 15}}
                //initialValue={dayjs(dateValue.split("-").reverse().join("-"))}
              >
                <TimePicker
                  allowClear={false}
                  format={"HH:mm"}
                  onSelect={(e) => onSelectTimeAnotation(e)}
                  //defaultValue={dayjs(dayjs(Date.now()).format('HH:mm'), 'HH:mm')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Notes"
            name="note"
            style={{marginTop: 8}}
            labelCol={{span: 4}}
            rules={[
              {
                required: true,
                message: "Required Field"
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item style={{display: "flex", justifyContent: "center"}}>
            <Button
              className="basic-btn"
              style={{marginRight: 20}}
              onClick={() => closeAnnotationModal()}
            >Back</Button>
            <Button
              className="main-btn"
              htmlType="submit"
            >Save comment</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row
        justify={'space-between'}
        className={styles.header}
      >
        <Col span={8} style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
        <Select
          defaultValue="grouped"
          style={{ width: 120 }}
          onChange={viewChange}
          options={[
            {
              value: 'simple',
              label: 'Simple view',
            },
            {
              value: 'grouped',
              label: 'Group view',
            }
          ]}
        />
        {/* <Badge count={notesList.length}>
          <Button
            className="main-btn"
            icon={<ContainerFilled />}
            onClick={() => openModalAnotationDay()}
            style={{marginLeft: 15}}
          >
            Notes
          </Button>
        </Badge> */}
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Form
            form={form}
            ref={formRef}
            initialValues={{datepicker: dayjs()}}
            style={{display: "flex", gap: 10}}
          >
            <Button onClick={() => prevDay(form.getFieldValue('datepicker'))}><LeftOutlined /></Button>
            <Form.Item
              name="datepicker"
              style={{marginBottom: 0}}
            >
              <DatePicker
                allowClear={false}
                //defaultValue={dayjs()}
                format={dateFormat}
                ref={dateSelected}
                className="datapickerElement"
                //value={setDateValue}
                //bordered={false}
                onChange={(newDate, newDateFormated) => changeTableDay(newDate, newDateFormated)}
              />
            </Form.Item>
            <Button onClick={() => nextDay(form.getFieldValue('datepicker'))} style={{marginLeft: "-5px"}}><RightOutlined /></Button>
          </Form>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "right", alignItems: "center"}}>
          <Form
            className="formSerachBooking"
            form={formSearch}
            onFinish={(values) => searchOnTable(values)}
            style={{marginRight: 20}}
          >
            <Form.Item
              name="text"
              style={{marginBottom: 0}}
            >
              <Input
                placeholder="Research..."
              />
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0}}
            >
              <Button
                className="iconCircle"
                htmlType="submit"
              ><SearchOutlined className="white" /></Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            shape="round"
            size='large'
            className={styles.button}
            onClick={() => showModal()}
            style={{marginRight: 6}}
          >
            <p><span>+</span>Booking</p>
          </Button>
        </Col>
      </Row>
      <Table
        columns={viewTable === 'simple' ? columnsSimple : columnsGrouped}
        dataSource={viewTable === 'simple' ? dataTableSimple : dataTableGrouped} 
        bordered
        onChange={onChange}
        className="mainTable"
        pagination={
          {
            total: dataTableSimple.length,
            pageSize: paginationItems,
            //defaultPageSize: 50,
            showSizeChanger: true,
            //onChange: (e) => console.log(e),
            pageSizeOptions: ['50', '100', '500', dataTableSimple.length],
            locale: { items_per_page: ""},
            showTotal: (total) => `${total} ${total < 2 ? "activité" : "Activity"} Today`,
          }
        }
        summary={pageData => summaryTable(pageData)}
        rowClassName={(record, index) => record.creator === userData.name ? "owner-b2b" : ""}
        onRow={(record, rowIndex) =>
          viewTable === 'simple' ?
            {
              onClick: event => openBookingInfo(record, rowIndex, event)
            }
            //onClick: event => openBookingInfo(record),
          : ""
        }
      />
    </>
  )
}