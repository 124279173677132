import React, {useEffect, useState} from "react";
import apiManager from "../../api/apiManager";
import { Form, Row, Col, Select, Input, Button } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import "./reasignresources.css";

export default function OverbookingResources (props) {

  //console.log(props)
  const [resourcesList, setResourcesList] = useState([])
  const [turnsList, setTurnsList] = useState([])
  const [formReasign] = Form.useForm()
  const actualResource = props.data.resource_id

  useEffect(() => {
    apiManager.getOverbookingTurnsAct(
      JSON.parse(localStorage.getItem("activeCenter")).id,
      props.data.cat_act_id,
      dayjs(props.data.start).format("DD-MM-YYYY")
    )
    .then((res) => {
      //console.log(res.data.data)
      let result = res.data.data
      setTurnsList([])
      result.map((turn) => {
        setTurnsList(current => [...current, {
          'label': turn.schedule,
          'title': turn.title,
          'value': turn.id
        }])
      })
    })
    apiManager.getOverbookingResources(
      props.data.cat_act_id,
      JSON.parse(localStorage.getItem("activeCenter")).id,
    )
    .then((res) => {
      //console.log(res.data.data)
      let result = res.data.data
      setResourcesList([])
      result.map((resource) => {
        setResourcesList(current => [...current, {
          'label': resource.title,
          'value': resource.id
        }])
      })
    })
  }, [])

  useEffect(() => {
    if (turnsList.length > 0) {
      formReasign.setFieldValue('newTurn', turnsList.find(o => o.label === dayjs(props.data.start).format("HH:mm")).value)
    }
  }, [turnsList])

  const reasignResource = (values) => {
    //console.log(actualResource)
    let data = {
      day: dayjs(props.data.start).format("DD-MM-YYYY"),
      turn: values.newTurn,
      activity: props.data.cat_act_id,
      center: JSON.parse(localStorage.getItem("activeCenter")).id,
      resource_occupation_id: props.data.event_id,
      resource_id: values.newResource
    }
    apiManager.reasignOverbookingResource(data)
    .then((res) => {
      //console.log(res.data.data)
      props.closeReasign()
    })
  }

  return (
    <>
      <Form
        form={formReasign}
        layout="vertical"
        className="reasignResourceForm"
        onFinish={() => reasignResource(formReasign.getFieldsValue())}
      >
        <Row justify="space-between" style={{alignItems: "flex-end", marginTop: 20}}>
          <Col span={24}>
            <h6
              style={{fontSize: 16, fontWeight: 600, marginBottom: 20}}
            >Reasignar recurso de la actividad <span className="primaryColor">{ props.data.title }</span> de las <span className="primaryColor">{ dayjs(props.data.start).format("HH:mm") }</span>h</h6>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Current resource"
              name="oldResource"
            >
              <Input defaultValue={props.data.resource_title} disabled={true} />
            </Form.Item>
          </Col>
          <Col span={2} style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 34}}>
            <SwapRightOutlined />
          </Col>
          <Col span={11}>
            <Form.Item
              label="New resource"
              name="newResource"
              rules={[
                {
                  required: true,
                  message: "Required Field"
                },
              ]}
            >
              <Select
                placeholder="Sélectionnez un New resource"
                options={resourcesList}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Change schedule"
              name="newTurn"
              rules={[
                {
                  required: true,
                  message: "Required Field"
                },
              ]}
            >
              <Select
                placeholder="Select new schedule"
                options={turnsList}
              />
            </Form.Item>
          </Col>
          <Col span={13}></Col>
          <Col span={24} style={{marginTop: 10}}>
            <Form.Item
              style={{marginBottom: 0, display: "flex", justifyContent: "center"}}
            >
              <Button
                className="basic-btn"
                onClick={() => props.closeReasign()}
              >Cancel</Button>
              <Button
                className="main-btn"
                style={{marginLeft: 20}}
                htmlType="submit"
              >Reasignar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}